<template>
  <div></div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import todayApi from '@/api/today';
import loginApi from '@/api/login.js';
import dashboardApi from '@/api/dashboard';

const store = useStore();
const router = useRouter();

const getImportSituation = () => {
  // console.log('id', store.state.account.user.id);
  todayApi
    .getContactImportSituation('', {
      userId: store.state.account.user.id,
    })
    .then(res => {
      if (res) {
        // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
        if (res === 'untreated') {
          router.replace('/today');
        } else if (res === 'Novice') {
          router.replace('/welcome');
        } else if (res === 'incomplete') {
          // debugger;
          getBehaviorPage();
        } else if (res === 'complete') {
          router.replace('/today');
        } else {
          debugger;
        }
      }
    });
};

const getBehaviorPage = () => {
  //  获取用户导入行为页面信息
  dashboardApi
    .getBehaviorPage({
      userId: store.state.account.user.id,
      importSituation: 0,
    })
    .then(res => {
      // 有数据就去我设置的页面，否去dashboard页面
      if (res) {
        // console.log('shuju shuju', res, typeof res);
        // console.log('13', res);
        router.replace({
          path: res,
          query: {
            importStatus: 0,
          },
        });
      } else {
        // console.log('14', res);
        router.replace({
          path: '/dashboard',
          query: {
            importStatus: 0,
          },
        });
      }
    });
};

onMounted(() => {
  let temp = window.location.href.split('?')[1];
  let prams = new URLSearchParams('?' + temp);
  let code = prams.get('code');

  loginApi
    .qrcodeLogin('', {
      code: code,
      createTime: localStorage.getItem('ctime'),
    })
    .then(res => {
      console.log(res);
      if (res.userStatus == 'login') {
        store.commit('account/setToken', res.token);
        store.commit('account/setUser', res);
        getImportSituation();
      } else {
        localStorage.setItem('code', code);
        router.replace('/bind');
      }
    });
});
</script>

<style lang="less" scoped></style>
